import { faBan, faWarning } from '@fortawesome/pro-regular-svg-icons'
import { Button } from 'antd'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import FaIconWithCircularBackground from '../../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'
import tailwindConfig from '../../../tailwind.config'
import {
  useDoNotService,
  useOpenAccountBalance,
} from './AccountDetailsBanner.gql'

const colors = tailwindConfig.theme.extend.colors

export const AccountDetailsBanner = ({
  account,
  className,
  itemClassName,
}: {
  account: { accountGuid: string }
  className?: string
  itemClassName?: string
}) => {
  const { accountGuid } = account
  const doNotServiceReason = useDoNotService(accountGuid)
  const openAccountBalance = useOpenAccountBalance(accountGuid)
  const overdueBalanceFormatted = openAccountBalance?.overdueBalanceFormatted
  if (!doNotServiceReason && !overdueBalanceFormatted) return null

  return (
    <div className={classNames('flex flex-col gap-2', className)}>
      {doNotServiceReason && (
        <DoNotServiceBanner
          doNotServiceReason={doNotServiceReason}
          className={itemClassName}
        />
      )}
      {overdueBalanceFormatted && (
        <OverdueAccountBalanceBanner
          overdueBalanceFormatted={overdueBalanceFormatted}
          oldestOpenInvoiceId={openAccountBalance?.oldestOpenInvoiceId}
          className={itemClassName}
        />
      )}
    </div>
  )
}

export const DoNotServiceBanner = ({
  doNotServiceReason,
  className,
}: {
  doNotServiceReason: string
  className?: string
}) => {
  return (
    <div
      className={classNames(
        'flex gap-3 rounded-xl border border-solid border-bz-orange-300 bg-bz-orange-100 px-4 py-3',
        className,
      )}
    >
      <FaIconWithCircularBackground
        iconDefinition={faBan}
        color={colors['bz-orange'][800]}
        backgroundColor={colors['bz-orange'][200]}
        diameterPx={28}
        noRightMargin
      />
      <div>
        <span className="regular_14_22">
          <span className="semibold_14_22">
            This account has been marked as Do Not Service for the following
            reason:
          </span>{' '}
          “{doNotServiceReason}”
        </span>
      </div>
    </div>
  )
}

export const OverdueAccountBalanceBanner = ({
  overdueBalanceFormatted,
  oldestOpenInvoiceId,
  className,
}: {
  overdueBalanceFormatted: string
  oldestOpenInvoiceId: string | null
  className?: string
}) => {
  return (
    <div
      className={classNames(
        'flex items-start justify-between gap-3 rounded-xl border border-solid border-bz-orange-300 bg-bz-orange-100 px-4 py-3',
        className,
      )}
    >
      <FaIconWithCircularBackground
        iconDefinition={faWarning}
        color={colors['bz-orange'][800]}
        backgroundColor={colors['bz-orange'][200]}
        diameterPx={28}
        noRightMargin
      />
      <div className="flex flex-1 flex-wrap justify-between gap-2">
        <div>
          <span className="regular_14_22">
            <span className="semibold_14_22">
              This account has an overdue balance of {overdueBalanceFormatted}.
            </span>{' '}
            Click “View Invoices” to address outstanding payments.
          </span>
        </div>
        {oldestOpenInvoiceId && (
          <Link
            to={`/invoice/${oldestOpenInvoiceId}`}
            data-testid="view-invoices-button"
          >
            <Button type="primary">View Invoices</Button>
          </Link>
        )}
      </div>
    </div>
  )
}
