import {
  PricebookItem,
  PricebookItemTypeEnum,
  bzExpect,
  formatMoney,
  normalizeString,
  uniqBy,
  upperPascalCaseToHumanReadable,
} from '@breezy/shared'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useMemo } from 'react'
import { HtmlRenderer } from 'src/elements/HtmlRenderer/HtmlRenderer'
import ColoredCircle from '../../elements/ColoredCircle/ColoredCircle'
import { MaybeDataPoint } from '../../elements/MaybeDataPoint/MaybeDataPoint'
import { useInMemorySearch } from '../../hooks/useInMemorySearch'
import { BehindFeatureFlag } from '../BehindFeatureFlag'
import { usePricebookAdmin } from './hooks/usePricebookAdmin'

type PricebookItemTableProps = {
  type: PricebookItemTypeEnum
}

const computePricebookItemIndex = (item: PricebookItem) => {
  return [item.name, item.description, item.pricebookCategory?.name]
    .filter(Boolean)
    .map(s => normalizeString(bzExpect(s, 'Filtered nullish values above')))
    .join('')
}

export const PricebookItemTable = ({ type }: PricebookItemTableProps) => {
  const {
    serviceItemClicked,
    materialItemClicked,
    equipmentItemClicked,
    laborItemClicked,
    pricebookItems,
  } = usePricebookAdmin()

  const { searchFilter, searchText, onSearch } =
    useInMemorySearch<PricebookItem>({
      wait: 1000,
      keyMapper: p => p.pricebookItemGuid,
      computeIndex: computePricebookItemIndex,
    })

  const columns: ColumnsType<PricebookItem> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '20%',
    },
    {
      title: 'Description',
      key: 'description',
      ellipsis: {
        showTitle: true,
      },
      width: '20%',
      render: (item: PricebookItem) => (
        <div className="max-h-[69px]">
          <BehindFeatureFlag
            enabledFeatureFlag="pricebook-rich-text-formatting"
            render={<HtmlRenderer htmlContent={item.description} />}
            fallback={item.description}
          />
        </div>
      ),
    },
    {
      title: 'Category',
      key: 'category',
      render: (item: PricebookItem) => {
        return <MaybeDataPoint>{item.pricebookCategory?.name}</MaybeDataPoint>
      },
      sorter: (a, b) =>
        (a.pricebookCategory?.name ?? '').localeCompare(
          b.pricebookCategory?.name ?? '',
        ),
      filters: uniqBy(
        bzExpect(pricebookItems)
          .filter(p => p.pricebookCategory)
          .map(i => i.pricebookCategory),
        'pricebookCategoryGuid',
      ).map(c => {
        return {
          text: bzExpect(c).name,
          value: bzExpect(c).pricebookCategoryGuid,
        }
      }),
      onFilter: (value, record) =>
        record.pricebookCategory?.pricebookCategoryGuid === value,
      ellipsis: {
        showTitle: true,
      },
      width: '15%',
    },
    {
      title: 'Cost',
      key: 'cost',
      dataIndex: 'costUSD',
      render: (cost: number) => `${formatMoney(cost)}`,
      ellipsis: {
        showTitle: true,
      },
      sorter: (a, b) => a.costUSD - b.costUSD,
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'priceUSD',
      render: (price: number) => `${formatMoney(price)}`,
      ellipsis: {
        showTitle: true,
      },
      sorter: (a, b) => a.priceUSD - b.priceUSD,
    },
    {
      title: 'Is Taxable?',
      key: 'taxable',
      dataIndex: 'isTaxable',
      render: (isTaxable: boolean) => (isTaxable ? 'Yes' : 'No'),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Is Discountable?',
      key: 'discountable',
      dataIndex: 'isDiscountable',
      render: (isDiscountable: boolean) => (isDiscountable ? 'Yes' : 'No'),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (item: PricebookItem) => {
        return (
          <div style={{ display: 'flex' }}>
            <ColoredCircle color={item.isActive ? 'limegreen' : 'lightgrey'} />
            <div
              style={{
                display: 'inline-block',
                color: item.isActive ? 'black' : 'lightgrey',
              }}
            >
              {item.isActive ? 'Active' : 'Disabled'}
            </div>
          </div>
        )
      },
      ellipsis: {
        showTitle: true,
      },
      filters: [
        { text: 'Active', value: true },
        { text: 'Disabled', value: false },
      ],
      onFilter: (value, record) => record.isActive === value,
    },
  ]

  const itemsToDisplay = useMemo(() => {
    const items = bzExpect(pricebookItems)
    return searchFilter(items.filter(i => i.itemType === type))
  }, [pricebookItems, type, searchFilter])

  if (!pricebookItems) return null

  return (
    <div>
      <div className="m-2 flex justify-between">
        <div>
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
            {upperPascalCaseToHumanReadable(type)} Items
          </span>{' '}
          <span style={{ fontSize: '12px', color: 'gray' }}>
            {itemsToDisplay.length} {type.toLowerCase()} items
          </span>
        </div>
        <div>
          <Input
            placeholder={`Search ${upperPascalCaseToHumanReadable(type)} Items`}
            prefix={
              <FontAwesomeIcon icon={faSearch} size="sm" className="mr-1" />
            }
            value={searchText}
            onChange={onSearch}
            style={{ minWidth: 300 }}
            allowClear
          />
        </div>
      </div>
      <Table
        rowKey="pricebookItemGuid"
        pagination={{
          defaultPageSize: 15,
          pageSizeOptions: [15, 30, 100, 250],
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              switch (type) {
                case PricebookItemTypeEnum.SERVICE:
                  serviceItemClicked(record)
                  break
                case PricebookItemTypeEnum.MATERIAL:
                  materialItemClicked(record)
                  break
                case PricebookItemTypeEnum.EQUIPMENT:
                  equipmentItemClicked(record)
                  break
                case PricebookItemTypeEnum.LABOR:
                  laborItemClicked(record)
                  break
                default:
                  throw new Error(`MissingCaseException: ${type}`)
              }
            },
          }
        }}
        columns={columns}
        dataSource={itemsToDisplay}
      />
    </div>
  )
}
